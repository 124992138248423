import startHome from '../../home/ui/startHome.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: startHome
  }
]

export default routes
