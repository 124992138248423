import store from '@/app/model/store/store'
import axios from 'axios'

export function checkGeolocation() {

  function postGeolocation() {
    if (store.state.localization.lat) {
      axios.post('/localizations', {
        'device_uuid': store.state.device_uuid,
        'lat': store.state.localization.lat,
        'lng': store.state.localization.lng,
        'speed': store.state.localization.speed,
        'heading': store.state.localization.heading,
        'accuracy': store.state.localization.accuracy,
      })
          .then(response => console.log(response.status))
    }
  }

  // checking position by watcher
  navigator.geolocation.watchPosition(pos => {
    store.commit('setLocalization', {
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
      speed: pos.coords.speed,
      heading: pos.coords.heading,
      accuracy: pos.coords.accuracy
    })
  }, err => {
    console.log(err.message)
  }, {
    enableHighAccuracy: true,
    timeout: 20000,
    maximumAge: 0
  })

  // sending first time location
  setTimeout(()=> {
    postGeolocation()
  }, 5000)

  // sending localization in loop to api
  setInterval(() => {
    postGeolocation()
  }, 60000)
}
