<template>
    <div class="flex w-screen bg-gray-100 border-b-2 overflow-x-hidden">
        <div class="flex-none p-2">
            <img src="/img/logo.png" alt="{{ title }} logo" class="w-20 md:w-32">
        </div>
        <div class="flex items-center py-5">
            <div>
                <h1 class="text-2xl sm:text-3xl md:text-5xl text-primary-700">{{ title }}</h1>
                <h2 class="text-md md:text-xl">{{ subtitle }}</h2>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title', 'subtitle']
}
</script>
