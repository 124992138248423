import { createApp } from 'vue'
import App from './app/ui/App.vue'
import router from './app/router'
import store from './app/model/store/store'
import moment from './app/plugins/moment'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './registerServiceWorker'
import './index.css'

const app = createApp(App)

axios.defaults.baseURL = process.env.VUE_APP_API_URL

app.use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(moment)
  .mount('#app')

app.config.compilerOptions.isCustomElement = tag => tag.startsWith('v-')
