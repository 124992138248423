import FingerprintJS from "@fingerprintjs/fingerprintjs";
import store from "@/app/model/store/store";

export function checkDevice() {
  const promise = FingerprintJS.load({monitoring: false})
  promise
      .then(fp => fp.get())
      .then(result => {
        store.state.device_uuid = result.visitorId
      })
}
