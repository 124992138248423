
function updateSubscriptionOnServer(subscription) {
  return fetch('https://api.team.fruitweb.pl/push_notifications/subscriptions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      subscription_json: JSON.stringify(subscription)
    })
  });
}

export function subscribeUser() {
  if('serviceWorker' in navigator) {
    const applicationServerKey = 'BPT8PVrvdX3IfeBvkYGRRSRG0cEhgtPFzd6IBqrSs90nrNnrlFrEtpbvECFDAvAJa9JJLj9JCW-O_KUJ9WCYm_o';

    navigator.serviceWorker.getRegistration().then(function (reg) {

      reg.pushManager.getSubscription().then(function (sub) {
        if (sub === null) {
          // Update UI to ask user to register for Push
          console.log('Not subscribed to push service!');
        } else {
          // We have a subscription, update the database
          console.log('Subscription object: ', sub);
        }
      });


      reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      }).then(function (subscription) {
        console.log('User is subscribed.');

        return updateSubscriptionOnServer(subscription);

      })
    })
  }
}
