import {createRouter, createWebHistory} from 'vue-router'

import homeRoutes from '@/home/router'
import budgetRoutes from '@/budget/router'
import localizationRoutes from '@/localization/router'
import shoppingRoutes from '@/shopping/router'
// import errorsRoutes from '@/errors/router'

const routes = [].concat(
    homeRoutes,
    budgetRoutes,
    localizationRoutes,
    shoppingRoutes,
    // errorsRoutes
)

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
