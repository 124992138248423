import axios from 'axios'
import _ from "lodash";

export default {
  namespaced: true,
  state() {
    return {
      emptyElement: {
        value: '',
        state: false
      },
      emptyShoppingList: {
        items: [{
          value: '',
          state: false
        }],
      },
      persistedShoppingList: {},
      shoppingList: {},
      shoppingListAll: []
    }
  },
  getters: {
    persistedShoppingList: state => state.persistedShoppingList,
    shoppingList: state => state.shoppingList,
    shoppingListAll: state => state.shoppingListAll
  },
  actions: {
    getShoppingListAll({commit, dispatch}) {
      axios.get('/shoppings')
          .then(result => {
            commit('SET_SHOPPING_LISTS', result.data)
          })
          .catch((error) => {
            dispatch('alert/axiosError', error.message, {root:true})
          })
    },
    getShoppingList({commit, dispatch}, id) {
      if (id) {
        axios.get(`/shoppings/${id}`)
            .then(result => {
              if (result) {
                commit('SET_SHOPPING_LIST', result.data)
              }
            })
            .catch((error) => {
              dispatch('alert/axiosError', error.message, {root:true})
            })
      } else {
        commit('RESET_SHOPPING_LIST')
      }
    },
    saveShoppingList({commit, dispatch, state}) {
      if (state.shoppingList.id) {
        axios.put(`/shoppings/${state.shoppingList.id}`,
            Object.assign({modified_at: new Date().toISOString()}, state.shoppingList))
            .then(result => {
              if (result) {
                commit('UPDATE_SHOPPING_LIST', result.data)
              }
            })
            .catch((error) => {
              dispatch('alert/axiosError', error.message, {root:true})
            })
      } else {
        axios.post('/shoppings', state.shoppingList)
            .then(result => {
              if (result) {
                commit('ADD_SHOPPING_LIST', result.data)
              }
            })
            .catch((error) => {
              dispatch('alert/axiosError', error.message, {root:true})
            })
      }
    },
    addElementToShoppingList({commit}) {
      commit('ADD_EMPTY_ELEMENT')
    },
    deleteElementFromShoppingList({commit}, index) {
      commit('DELETE_ELEMENT', index)
    },

    deleteShoppingList({state, commit, dispatch}, listId) {
      axios.delete(`/shoppings/${listId}`)
          .then(result => {
            if (result) {
              commit('DELETE_SHOPPING_LIST', listId)
              if(state.shoppingListAll.length === 0) {
                dispatch('alert/addError', 'Lista jest pusta.', {root:true})
              }
            }
          })
          .catch((error) => {
            dispatch('alert/axiosError', error.message, {root:true})
          })
    }
  },
  mutations: {
    SET_SHOPPING_LISTS(state, data) {
      state.shoppingListAll = data
    },
    ADD_SHOPPING_LIST(state, data) {
      state.shoppingListAll.push(data)
      state.shoppingList = data
      state.persistedShoppingList = _.cloneDeep(data)
    },
    ADD_EMPTY_ELEMENT(state) {
      console.log('add element')
      state.shoppingList.items.push(Object.assign({}, state.emptyElement))
    },
    DELETE_ELEMENT(state, index) {
      console.log('remove element')
      state.shoppingList.items = state.shoppingList.items.filter((item, i) => i !== index)
    },
    RESET_SHOPPING_LIST(state) {
      state.shoppingList = _.cloneDeep(state.emptyShoppingList)
      state.persistedShoppingList = _.cloneDeep(state.shoppingList)
    },
    SET_SHOPPING_LIST(state, data) {
      state.shoppingList = data
      state.persistedShoppingList = _.cloneDeep(data)
    },
    UPDATE_SHOPPING_LIST(state, data) {
      state.shoppingList = data
      state.persistedShoppingList = _.cloneDeep(data)
    },
    DELETE_SHOPPING_LIST(state, id) {
      state.shoppingListAll = state.shoppingListAll.filter(item => item.id !== id)
    }
  }
}
