<template>
  <div class="flex flex-wrap w-full overflow-x-auto z-20 text-center border-t-2">
    <nav class="flex justify-center flex-wrap bg-gray-100 grow">
      <button href="" @click="reloadPage" class="p-3 btn-primary m-1">
        <refresh-icon class="w-8 h-8"></refresh-icon>
      </button>
      <router-link class="flex items-center p-3 md:text-lg border-b-2 btn-primary border-gray-200 m-1" to="/">
        <home-icon class="inline w-8 h-8 mr-2"></home-icon>
        <span class="hidden sm:inline">Home</span>
      </router-link>
      <router-link class="flex items-center p-3 md:text-lg border-b-2 btn-primary border-gray-200 m-1" to="/shopping">
        <shopping-bag-icon class="inline w-8 h-8 mr-2"></shopping-bag-icon>
        <span class="hidden sm:inline">Zakupy</span>
      </router-link>
      <router-link class="flex items-center p-3 md:text-lg border-b-2 btn-primary border-gray-200 m-1" to="/budget">
        <currency-dollar-icon class="inline w-8 h-8 mr-2"></currency-dollar-icon>
        <span class="hidden sm:inline">Budżet</span></router-link>
      <router-link class="flex items-center p-3 md:text-lg border-b-2 btn-primary border-gray-200 m-1" to="/localization">
        <location-marker-icon class="inline w-8 h-8 mr-2"></location-marker-icon>
        <span class="hidden sm:inline">Lokalizacja</span>
      </router-link>
    </nav>
    <p class="w-full bg-gray-100 text-xs p-1 text-center text-gray-500">Wersja: {{ version }}</p>
  </div>
</template>

<script>
import {RefreshIcon, HomeIcon, ShoppingBagIcon, CurrencyDollarIcon, LocationMarkerIcon} from '@heroicons/vue/outline/esm'

export default {
  components: { RefreshIcon, HomeIcon, ShoppingBagIcon, CurrencyDollarIcon, LocationMarkerIcon },
  data() {
    return {
      version: process.env.VUE_APP_VERSION
    };
  },
  methods: {
    reloadPage() {
      window.location.reload();
    }
  }
}
</script>

<style>
  nav {
    .btn-primary {
      display: block;
      @apply border-accent;
    }
    .btn-primary:focus {
      @apply border-accent;
    }
    .btn-primary.router-link-active {
      @apply bg-secondary;
      @apply text-secondary-content;
      @apply border-secondary;
    }
  }
</style>
