<template>
  <div>
    <t-header title="Witamy w Family Center"></t-header>
    <div class="flex flex-1"></div>
    <t-menu></t-menu>
  </div>
</template>

<script>
  import THeader from '@/app/ui/components/THeader.vue'
  import TMenu from '@/app/ui/components/TMenu.vue'

  export default {
    components: {
      THeader,
      TMenu
    },
    methods: {
      reloadPage() {
        window.location.reload();
      }
    }
  }
</script>
