import { v4 as uuidv4 } from 'uuid';

export default {
  namespaced: true,
  state() {
    return {
      alerts: []
    }
  },
  getters: {
    alerts: state => state.alerts
  },
  actions: {
    addSuccess({commit}, title, dismissible = true) {
      commit('ADD_SUCCESS', {title, dismissible})
    },
    addError({commit}, title, dismissible = true) {
      commit('ADD_ERROR', {title, dismissible})
    },
    axiosError({commit}, title, dismissible = true) {
      commit('ADD_ERROR', {title, dismissible})
    },
    clearAlert({commit}, uuid) {
      commit('CLEAR_ALERT', uuid)
    },
    clearAllAlert({commit}) {
      commit('CLEAR_ALL_ALERT')
    }
  },
  mutations: {
    ADD_SUCCESS(state, {title, dismissible}) {
      state.alerts.push({
        uuid: uuidv4(),
        title: title,
        type: 'success',
        dismissible: dismissible
      })
    },
    ADD_ERROR(state, {title, dismissible}) {
      state.alerts.push({
        uuid: uuidv4(),
        title: title,
        type: 'error',
        dismissible: dismissible
      })
    },
    CLEAR_ALERT(state, uuid) {
      console.log('clear alert')
      state.alerts = state.alerts.filter((item) => item.uuid !== uuid)
    },
    CLEAR_ALL_ALERT(state) {
      console.log('clear all alert')
      state.alerts = []
    }
  }
}
