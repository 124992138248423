<template>
  <div class="h-screen w-screen overflow-x-hidden">
    <t-alerts></t-alerts>
    <transition>
      <router-view class="flex flex-col min-h-full overflow-y-auto overflow-x-hidden" v-slot="{ Component }">
        <component :is="Component" class="overflow-hidden"/>
      </router-view>
    </transition>
    <!--    <aside class="hidden lg:flex flex-col items-center bg-white shadow h-full">-->
    <!--      abcd-->
    <!--    </aside>-->
  </div>
</template>

<script>
import TAlerts from '@/app/ui/components/TAlerts.vue'
import {checkDevice} from "@/app/model/store/device";
import {checkGeolocation} from "@/app/model/store/geolocation";
import {subscribeUser} from "@/app/model/store/pushSubscribe";

export default {
  created() {
    checkDevice()
    checkGeolocation()
    subscribeUser()
  },
  components: {
    TAlerts
  },
  watch: {
    '$route'() {
      this.$store.dispatch('alert/clearAllAlert')
    }
  }
}
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
  overflow: hidden !important;
  height: 100vh;
}

.v-leave-active {
  position: fixed;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
