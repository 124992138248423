import {createStore} from 'vuex'
import alertStore from './alert'
import shoppingStore from '@/shopping/store'

export default createStore({
  state: {
    device_uuid: '',
    localization: {
      device_uuid: '',
      lat: '',
      lng: '',
      date: '',
      speed: '',
      heading: '',
      accuracy: ''
    }
  },
  mutations: {
    setLocalization(state, object) {
      state.localization.device_uuid = state.device_uuid,
      state.localization.lat = object.lat,
      state.localization.lng = object.lng,
      state.localization.speed = object.speed,
      state.localization.heading = object.heading,
      state.localization.accuracy = object.accuracy,
      state.localization.date = new Date().toISOString()
    }
  },
  getters: {
    localization: state => state.localization,
  },
  actions: {},
  modules: {
    shopping: shoppingStore,
    alert: alertStore
  }
})
