const routes = [
  {
    path: '/shopping',
    name: 'Shopping home',
    component: () => import('../../shopping/ui/shoppingHome.vue'),
    children: [
      {
        path: '',
        name: 'Shopping list',
        component: () => import('../../shopping/ui/shoppingList.vue')
      },
      {
        path: 'item/:itemId',
        name: 'Shopping item',
        props: true,
        component: () => import('../../shopping/ui/shoppingItem.vue')
      },
      {
        path: 'item',
        name: 'Shopping item add',
        component: () => import('../../shopping/ui/shoppingItem.vue')
      }
    ]
  },
  {
    path: '/shopping-paragons',
    name: 'Shopping paragons',
    component: () => import('../../shopping/ui/shoppingParagonsHome.vue'),
    children: [
      {
        path: '',
        name: 'Shopping paragons list',
        component: () => import('../../shopping/ui/shoppingParagonsList.vue')
      },
      {
        path: 'add',
        name: 'Shopping paragons add',
        component: () => import('../../shopping/ui/shoppingParagonsAdd.vue')
      }
    ]
  }
]

export default routes
