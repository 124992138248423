<template>
  <div class="fixed top-0 right-0 z-[5000]">
    <div v-for="alert in alerts" :key="alert.uuid" role="alert"
         :class="['alert m-4 p-4 rounded  min-w-[250px] shadow-lg transition-all animate-pulse', alert.type]">
      <div class="flex-1 align-center">
      <span>
        <check-circle-icon v-if="alert.type==='success'" class="inline w-6 h-6 mr-2"></check-circle-icon>
        <exclamation-circle-icon v-if="alert.type==='error'" class="inline w-6 h-6 mr-2"></exclamation-circle-icon>
      </span>
        <label>{{ alert.title }}</label>
        <button @click="closeAlert(alert.uuid)" v-if="alert.dismissible">
          <x-circle-icon class="inline w-6 h-6 ml-4"></x-circle-icon>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {CheckCircleIcon, ExclamationCircleIcon} from '@heroicons/vue/outline/esm'
import {XCircleIcon} from '@heroicons/vue/solid/esm'

export default {
  data() {
    return {}
  },
  props: ['title', 'type', 'dismissible'],
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    XCircleIcon
  },
  computed: {
    alerts() {
      return this.$store.getters['alert/alerts']
    }
  },
  methods: {
    closeAlert(uuid) {
      this.$store.dispatch('alert/clearAlert', uuid)
    }
  }
}
</script>

<style>
.alert {
  width: auto !important;

  &.error {
    @apply bg-red-400;
    @apply text-white;
  }
}
</style>
